
  export default {
    name: 'BaseHeading',
    inject: {
      theme: {
        default: () => ({isDark: false}),
      },
      heading: {
        default: () => ({align: 'left'}),
      },
    },
    provide() {
      return {
        heading: {
          align: this.align,
        },
      };
    },
    props: {
      align: {
        type: String,
        default() {
          return this.heading.align;
        },
      },
      tag: {
        type: String,
        default: 'h1',
      },
      title: {
        type: String,
        default: undefined,
      },
      withLineBreaks: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        titleWithLineBreaks: '',
      };
    },
    created() {
      this.titleWithLineBreaks = this.title.replace(/\n/g, '<br>');
    },
  };
