
  import linkClickRouting from '@/mixins/linkClickRouting';
  export default {
    name: 'RichText',
    mixins: [linkClickRouting],
    props: {
      content: {
        type: String,
        required: false,
        default: null,
      },
    },
  };
