import {arraysEqual} from '@/helper/ArrayHelper';

export function saveNewSearch(newSearch, searches) {
  let searchesForPage = [];
  const searchesOther = [];

  if (searches && searches.length > 0) {
    searches.map((item) => {
      if (item.page === newSearch.page) {
        searchesForPage.push(item);
      } else {
        searchesOther.push(item);
      }
    });
  }
  const found = searchesForPage.find(
    (element) =>
      element.page === newSearch.page &&
      element.what === newSearch.what &&
      element.where === newSearch.where &&
      arraysEqual(element.activities, newSearch.activities) &&
      arraysEqual(element.branches, newSearch.branches) &&
      arraysEqual(element.employmentTypesOr, newSearch.employmentTypesOr)
  );
  if (!found) {
    searchesForPage.unshift(newSearch);
  }
  searchesForPage = searchesForPage.slice(0, 3);
  return [...searchesForPage, ...searchesOther];
}
