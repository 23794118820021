
  import {prepareImageUrl} from '@/helper/ImageHelper';
  import HomepageSearchBox from '@/components/HomepageSearchBox';
  import RichText from '@/components/elements/RichText.vue';
  import ScreenSize from '@/mixins/ScreenSize';

  export default {
    name: 'SectionHomepageHero',
    components: {
      HomepageSearchBox,
      RegistrationOrLoginDialog: () =>
        import('@/components/candidate/RegistrationOrLoginDialog'),
      RichText,
    },
    mixins: [ScreenSize],
    provide: {
      theme: {isDark: true},
    },
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        numberOfJobs: 0,
        tweenedNumber: 0,
        imageUrl: require(process.env.NUXT_ENV_FALLBACK_MEDIUM_HERO_IMAGE),
      };
    },
    async fetch() {
      this.numberOfJobs = await this.$store.dispatch(
        'api/jobPosting/getJobPostingsTotalNumber'
      );
      this.numberOfJobs = this.numberOfJobs
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    computed: {
      calculateHeight() {
        let height;
        if (!this.section.registration_button && !this.section.jobs_button) {
          height = 500;
        } else if (
          this.section.registration_button ||
          this.section.jobs_button
        ) {
          height = 560;
        }
        return height;
      },
    },
    created() {
      const imageUrl = this.section.image
        ? this.isMobile &&
          this.section.image.formats.medium &&
          this.section.image.formats.medium.url
          ? this.section.image.formats.medium.url
          : this.section.image.url
        : false;

      if (imageUrl) {
        this.imageUrl = prepareImageUrl(imageUrl);
      }
    },
  };
