
  import {
    prepareGeoApifyReverseSearchUrl,
    prepareGeoApifySearchUrl,
  } from '@/helper/UrlHelper';
  import {saveNewSearch} from '@/helper/LastSearchesHelper';
  import clearableTabIndex from '@/mixins/clearableTabIndex';
  import axios from 'axios';
  export default {
    mixins: [clearableTabIndex],
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        what: null,
        distance: 25000,
        disabled: false,
        descriptionLimit: 60,
        whereEntries: [],
        autocomplete_osm_model: null,
        whereSearch: null,
      };
    },
    watch: {
      filterParams: function () {
        this.setFilterValues();
      },
    },
    methods: {
      fetchWhereEntriesByTitle(val) {
        if (this.autocomplete_osm_model !== null) {
          return;
        }
        // cancel pending call
        clearTimeout(this._timerId);

        // delay new call
        this._timerId = setTimeout(() => {
          if (process.client) {
            axios
              .get(prepareGeoApifySearchUrl(val))
              .then((searchResults) => {
                this.whereEntries = searchResults.data.features;
              })
              .catch((e) => {
                if (e.response.status !== 200) {
                  this.$notifications.showNotification({
                    message: this.$t('geolocation.error_message'),
                    color: 'error',
                    timeout: 5000,
                  });
                }
              });
          }
        }, 500);
      },
      async applyFilter() {
        this.disabled = true;
        setTimeout(() => (this.disabled = false), 3000);
        const params = {};
        if (this.what) {
          params.search = String(this.what);
          params.user_search = '1';
        }

        if (this.whereSearch && !this.autocomplete_osm_model) {
          this.$notifications.showNotification({
            message: this.$t('filter.missing_select_value'),
            color: 'warning',
            timeout: 5000,
          });
        } else {
          if (this.autocomplete_osm_model) {
            params.whereInput = String(
              this.autocomplete_osm_model.properties.formatted
            );
            params.distance = `${this.autocomplete_osm_model.properties.lat},${this.autocomplete_osm_model.properties.lon},${this.distance}`;
            params.user_search = '1';
            params.osm_id = this.autocomplete_osm_model.properties.place_id;
          }

          if (this.whereSearch || this.what) {
            const url =
              this.localePath({
                name: 'jobs',
                query: params,
              }) +
              `?${this.filterParams}` +
              `&page=1`;

            const newSearch = {
              page: '/jobs',
              url: url,
              what: this.what || '',
              where: this.whereSearch || '',
              activities: [],
              branches: [],
              employmentTypesOr: [],
            };

            await this.$localforage.setItem(
              'SavedSearches',
              saveNewSearch(
                newSearch,
                await this.$localforage.getItem('SavedSearches')
              )
            );
          }

          // redirect to joblisting with query
          this.$router.push(
            this.localePath({
              name: 'jobs',
              query: params,
            })
          );
        }
      },
      resetFilterValues() {
        this.what = null;
        this.distance = 25000;
      },
      getGeoLocation() {
        new Promise((resolve, reject) => {
          if (!navigator.geolocation) {
            reject();
          }
          // https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions
          // ohne Timeout wartet er EWIG
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            timeout: 3000,
            enableHighAccuracy: true,
          });
        })
          .then((value) => {
            axios
              .get(
                prepareGeoApifyReverseSearchUrl(
                  value.coords.latitude,
                  value.coords.longitude
                )
              )
              .then((result) => {
                this.autocomplete_osm_model = result.data.features[0];
                this.whereEntries = result.data.features;
                this.whereSearch = result.data.features[0].properties.formatted;
              })
              .catch((e) => {
                if (e.response.status !== 200) {
                  this.$notifications.showNotification({
                    message: this.$t('geolocation.error_message'),
                    color: 'error',
                    timeout: 5000,
                  });
                }
              });
          })
          .catch((e) => {
            this.$notifications.showNotification({
              message:
                e.code === 1
                  ? this.$t('geolocation.is_denied')
                  : this.$t('geolocation.unavailable'),
              color: 'error',
              timeout: 5000,
            });
          });
      },
    },
  };
